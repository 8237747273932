<template>
  <div  class=" ma-4 mb-16 px-0 px-sm-8" >

    <div class="d-flex">
      <v-btn icon small class="px-4 mt-6" to="/profile/menu" v-if="$vuetify.breakpoint.mobile">
        <v-icon size=20>mdi-arrow-left</v-icon>
      </v-btn>

      <div class="text-h6 font-weight-bold my-6">佣金提现</div>
    </div>
    
    <div class="d-flex justify-space-between">
      
      <v-btn  dark outlined  tile class="main-btn addbtn" @click="showForm=!showForm">添加提现账号</v-btn>
    </div>

    <div>
      <v-container v-if="showForm">
        <v-row>
          <v-col cols="12" class="px-4">
            <v-radio-group v-model="form.account_type" row >
              <v-radio
                label="微信[调试中]"
                disabled
                value=2
              ></v-radio>
              <v-radio
                label="支付宝"
                value=3
              ></v-radio>
            </v-radio-group>
          </v-col>

          

          <v-col cols="12" md="4" class="px-4">
            <v-text-field
              label="真实姓名"
              required
              v-model="form.realname"
              class="mt-0 pt-0"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="4" class="px-4"  >
            <v-text-field
              label="电话"
              required
              v-model="form.mobile"
              class="mt-0 pt-0"
            ></v-text-field>
          </v-col>
          
          <v-col cols="12" md="4" class="px-4" v-if="form.account_type==3">
            <v-text-field
              label="支付宝账号"
              required
              v-model="form.account"
              class="mt-0 pt-0"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3"  class="px-4 offset-md-6">
            <v-btn  dark outlined  tile class="main-btn" @click="cancle">取消</v-btn>
          </v-col>
          <v-col cols="12" md="3"  class="px-4 ">
            <v-btn  dark outlined  tile class="main-btn" @click="save">保存提现账号</v-btn>
          </v-col>
          
        </v-row>
      </v-container>
    </div>

    <v-list-item-group
        class="mt-8"
        active-class=""
        color="primary"
        v-model="accountid"
      >
        <v-list-item v-for="account in accounts" :key="account.id" :value="account.id">
          <template v-slot:default="{ active }">
            <v-list-item-action>
              <v-checkbox :input-value="active"></v-checkbox>
            </v-list-item-action>

            <v-list-item-content>
              
                <v-row>
                  <v-col>
                    <div>{{account.account_type_name}}</div>
                    <div>{{account.mobile}}</div>
                  </v-col>
                  <v-col>
                     {{account.realname}}
                    
                  </v-col>
                  <v-col>
                     {{account.account_type==3?account.account_number:'-'}}
                    
                  </v-col>
                  
                  <v-col>
                     <v-btn
                        class="ma-1"
                        plain
                        @click.stop="update(account.id)"
                      >修改
                      </v-btn>
                  </v-col>
                </v-row>
             
            </v-list-item-content>
          </template>
        </v-list-item>
    </v-list-item-group>

    <div>
      <v-container>
        <v-row>
          <v-col cols="12" md="4" class="px-4  offset-md-8">
            <v-text-field
              label="提现金额"
              required
              v-model="cash"
              class="mt-0 pt-0"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="4"  class="px-4 offset-md-8">
            <v-btn  dark outlined  tile class="main-btn" @click="go">确认提现</v-btn>
          </v-col>
          
        </v-row>
      </v-container>
    </div>

    
   


  </div>
</template>

<script>

import userApi from '@/api/user'
export default {


  data: () => ({
    showForm:false,
    accounts: {},
    accountid :0,
    cash:0,
    form:{account_type:3}
    

  
    //
  }),
  computed: {


  },
  created() {


    userApi.getwithdrawaccount().then(res => {
      this.accounts = res.data

    })
    
    
  },
  methods: {
    homesearch() {
      alert(3)
    },
    cancle() {
      this.form = {account_type:3}
      this.showForm = false
    },
    save() {

      let params = this.form
      if (params.account_type!=2 && params.account_type!=3) {
        this.$message.warning("请选择提现账号类型！")
        return
      }
      if (params.realname=="" || params.realname==undefined) {
        this.$message.warning("请输入真实姓名！")
        return
      }
      if (params.mobile=="" || params.mobile==undefined) {
        this.$message.warning("请输入电话号码！")
        return
      }
      if (params.account_type==3 && (params.account==undefined||params.account=="")) {
        this.$message.warning("请输入支付宝账号！")
        return
      }
      userApi.addwithdrawaccount(params).then(res => {
        if (res.code==0) {
          this.$message.success("保存成功！")
          this.form = {}
          this.showForm = false

          userApi.getwithdrawaccount().then(res => {
            this.accounts = res.data

          })

        } else {
          this.$message.warning("保存失败！")
        }
      })

    },
    
    update(id) {
      let info = this.accounts.filter(item => item.id==id).pop()

      this.form = {
        id:id,
        account_type : info.account_type,
        realname : info.realname,
        account :info.account_number,
        mobile : info.mobile,
    
        

      }
      
      this.showForm = true

    },
    go () {
      let params = {bank_account_id:this.accountid, cash:this.cash}
      userApi.withdraw(params).then(res => {
        console.log(typeof res.data,res.data)
        if (typeof res.data == "string") {
          this.$message.success(res.data,res.data)
          

        } else if (typeof res.data == "number") {
          this.$message.warning("申请已提交成功！请耐心等待审核。")
        }
      })
    }

    
  
  },
};
</script>
<style scoped>

.addbtn {
  
  width:150px;
}
</style>

